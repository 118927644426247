import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import DataTable, { createTheme } from "react-data-table-component";
import Swal from "sweetalert2";
import Select from "react-select";

import styled, { keyframes } from "styled-components";
import {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
  useMemo,
  Fragment,
} from "react";
import { AppContext } from "../../Pages/AuthContext";
import { decodeJWT } from "../../Components/Utilities";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const StyledDivSpacer = styled.div`
  height: ${(props) => (props.$height ? props.$height : "1rem")};
`;

const ReactSelectorContainer = styled.div`
  width: 250px;
`;

const rotate360 = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

const Spinner = styled.div`
  margin: 16px;
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

const Input = styled.input`
  color: #000;
  font-size: 1em;
  border: 2px solid rgb(59 130 246 / 0.5);
  border-radius: 20px;
  margin: 1em;
  padding: 0.5em;

  &:focus-visible {
    outline: none;
  }
`;

const SearchTable = () => {
  const [listOfOwner, setListOfOwner] = useState(false);
  const [collectionsInfo, setCollectionsInfo] = useState([]);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [docEdited, setDocEdited] = useState(false);
  const [blackChecked, setBlackChecked] = useState(false);
  const [badChecked, setBadChecked] = useState(false);
  const [unsubscribedChecked, setUnsubscribedChecked] = useState(false);
  const [allTablesChecked, setAllTablesChecked] = useState(true);
  const [tableSelected, setTableSelected] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [registersMatched, setRegistersMatched] = useState(0);
  const [selectAllRegisters, setSelectAllRegisters] = useState(false);
  const allRegistersChecked = useRef(selectAllRegisters);
  const [loadingTable, setLoadingTable] = useState(false);

  function getMaxWidth(data, key, characterWidth = 10) {
    const minWidth = 170;

    let maxTextLength = 0;
    data.forEach((item) => {
      const length =
        (item[key] ? item[key].toString().length : 0) * characterWidth;
      if (length > maxTextLength) {
        maxTextLength = length;
      }
    });

    return Math.max(maxTextLength, minWidth);
  }

  const columns = [
    {
      name: "ID",
      selector: (row) => row.reduced_id,
      width: "180px",
    },
    {
      name: "Base de datos",
      selector: (row) => row.database,
      width: `${getMaxWidth(data, "database")}px`,
    },
    {
      name: "Nombre",
      selector: (row) => row.name,
      width: `${getMaxWidth(data, "name")}px`,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      width: `${getMaxWidth(data, "email")}px`,
    },
    {
      name: "Teléfono",
      selector: (row) => row.phone,
      width: `${getMaxWidth(data, "phone")}px`,
    },
    {
      name: "Empresa",
      selector: (row) => row.company,
      width: `${getMaxWidth(data, "company")}px`,
    },
    {
      name: "Puesto",
      selector: (row) => row.role,
      width: `${getMaxWidth(data, "role")}px`,
    },
    {
      name: "Creado en",
      selector: (row) => row.created_at,
      width: `${getMaxWidth(data, "created_at")}px`,
    },
    {
      name: "Modificado en",
      selector: (row) => row.updated_at,
      width: `${getMaxWidth(data, "updated_at")}px`,
    },
    {
      name: "Creado por",
      selector: (row) => row.created_by,
      width: `${getMaxWidth(data, "created_by")}px`,
    },
    {
      name: "Modificado por",
      selector: (row) => row.updated_by,
      width: `${getMaxWidth(data, "updated_by")}px`,
    },
  ];

  useEffect(() => {
    allRegistersChecked.current = selectAllRegisters; // Actualizar la referencia con el último valor
  }, [selectAllRegisters]);

  const { userData } = useContext(AppContext);

  const ROLES = {
    Vendedor: 1085,
    Spammer: 1197,
    Administrador: 1356,
  };

  const selectHeight = 40;
  const customStylesSelector = {
    control: (base, state) => ({
      ...base,
      height: selectHeight,
      minHeight: selectHeight,
    }),
    valueContainer: (base, state) => ({
      ...base,
      height: selectHeight,
      padding: "0 6px",
    }),
    input: (base, state) => ({
      ...base,
      margin: "0px",
    }),
    indicatorsContainer: (base, state) => ({
      ...base,
      height: selectHeight,
    }),
  };

  let payload;
  let isAuthenticated;
  let userEmail;
  let isAdmin;
  let isSpammer;
  let isSeller;
  let roleId;

  if (userData.hasOwnProperty("access_token")) {
    payload = decodeJWT(userData.access_token);
    roleId = payload.role;
    isAuthenticated = roleId === 1085 || roleId === 1197 || roleId === 1356;
    userEmail = payload.email;
    isAdmin = roleId === ROLES["Administrador"];
    isSpammer = roleId === ROLES["Spammer"];
    isSeller = roleId === ROLES["Vendedor"];
  }

  const labelForBlackAndBadList = (value) => {
    if (value === "black_list") {
      return "Lista negra";
    }
    if (value === "bad_list") {
      return "Lista malos";
    }
    if (value === "unsubscribed") {
      return "Bajas";
    }
    return value;
  };

  const getAdminInfo = async () => {
    await fetch(`${import.meta.env.VITE_API_URL}/lists/`)
      .then((response) => response.json())
      .then((data) => {
        const listOptions = data.content.map((item) => ({
          value: item.table_name,
          label: item.table_name,
          ownerEmail: item.owner_email,
        }));
        listOptions.push(
          { value: "black_list", label: "Lista negra", ownerEmail: userEmail },
          { value: "bad_list", label: "Lista malos", ownerEmail: userEmail },
          { value: "unsubscribed", label: "Bajas", ownerEmail: userEmail }
        );
        setCollectionsInfo(listOptions);
      })
      .catch((err) => {
        console.log("error - getAdminInfo");
      });
  };

  const getOwnerInfo = async () => {
    fetch(`${import.meta.env.VITE_API_URL}/lists/owner_user`, {
      method: "POST",
      body: JSON.stringify({
        owner: userEmail,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const listOptions = data.content.map((item) => ({
          value: item.table_name,
          label: labelForBlackAndBadList(item.table_name),
          ownerEmail: item.owner_email,
        }));
        setCollectionsInfo(listOptions);
      })
      .catch((err) => {
        console.log("error - getOwnerInfo");
      });
  };

  useEffect(() => {
    if (isAdmin || isSeller) getAdminInfo();
    if (isSpammer) getOwnerInfo();
  }, [roleId]);

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
    if (state.selectedRows.length < 1000) {
      setSelectAllRegisters(false);
    }
  }, []);

  const handleBlackCheck = (event) => {
    setBlackChecked(event.target.checked);
  };

  const handleBadCheck = (event) => {
    setBadChecked(event.target.checked);
  };

  const handleUnsubscribedCheck = (event) => {
    setUnsubscribedChecked(event.target.checked);
  };

  const handleAllTAblesCheck = (event) => {
    setBadChecked(false);
    setBlackChecked(false);
    setUnsubscribedChecked(false);
    setAllTablesChecked(event.target.checked);
    if (event.target.checked) {
      setDocEdited(!docEdited);
      setSelectedOptions([]);
    } else {
      setData([]);
      setRegistersMatched(0);
    }
  };

  const getSearchData = async (search) => {
    await fetch(`${import.meta.env.VITE_API_URL}/lists/find`, {
      method: "POST",
      body: JSON.stringify({
        search_criteria: search,
        black_list: blackChecked,
        bad_list: badChecked,
        unsubscribed_list: unsubscribedChecked,
        search_by_table: !allTablesChecked,
        table_to_search: tableSelected,
        owner: userEmail,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hasOwnProperty("success")) {
          setData(data.content.documents);
          setRegistersMatched(data.content.registers_matched);
        }
        if (data.hasOwnProperty("error")) {
          Swal.fire({
            title: "Ha habido un problema",
            text: data.message,
            icon: "warning",
          });
        }
        if (data.hasOwnProperty("detail")) {
          Swal.fire({
            title: "Ha ocurrido un error",
            text: data.detail,
            icon: "error",
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Error!",
          text: err.message,
          icon: "error",
        });
      })
      .finally(() => {
        setLoadingTable(false);
      });
  };

  useEffect(() => {
    setLoadingTable(true);
    const debounce = setTimeout(() => {
      getSearchData(search);
    }, 500);
    return () => clearTimeout(debounce);
  }, [search, blackChecked, badChecked, unsubscribedChecked, docEdited]);

  const ModifyDialog = () => {
    const [open, setOpen] = useState(false);
    const allowedFields = ["name", "email", "phone", "company", "role"];

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
      setToggleCleared(!toggleCleared);
    };

    const DynamicInputsComponent = ({ formData }) => {
      const [formValues, setFormValues] = useState(formData[0]);
      const handleChange = (event, fieldName) => {
        const { value } = event.target;
        setFormValues((prevFormValues) => {
          return prevFormValues.map((field) => {
            if (field.name === fieldName) {
              return { ...field, value: value };
            }
            return field;
          });
        });
      };

      return (
        <>
          {formValues.map((field, index) => (
            <Fragment key={index}>
              <Grid item xs={5}>
                <Typography
                  variant="button"
                  display="block"
                  gutterBottom
                  paddingTop={"18px"}
                  fontWeight={"bold"}
                >
                  {translateToSpanish(field.name)}
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  key={index}
                  autoFocus
                  required={field.name === "email"}
                  id={`outlined-required-${index}`}
                  label={field.name === "email" ? "Requerido" : null}
                  name={field.name}
                  value={field.value}
                  type="text"
                  fullWidth
                  variant="standard"
                  onChange={(event) => handleChange(event, field.name)}
                />
              </Grid>
            </Fragment>
          ))}
        </>
      );
    };

    const translateToSpanish = (field) => {
      const translations = {
        name: "Nombre",
        email: "Email",
        phone: "Teléfono",
        company: "Empresa",
        role: "Puesto",
      };
      return translations[field];
    };

    const modifyDocument = async (requestBody) => {
      await fetch(`${import.meta.env.VITE_API_URL}/lists/update_document`, {
        method: "PUT",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.hasOwnProperty("success")) {
            setDocEdited(!docEdited);
            Swal.fire({
              title: "Éxito",
              text: "Registro editado correctamente",
              icon: "success",
            });
          }
          if (data.hasOwnProperty("detail")) {
            Swal.fire({
              title: "Error",
              text: data.detail,
              icon: "error",
            });
          }
          if (data.hasOwnProperty("error")) {
            Swal.fire({
              title: "Error",
              text: data.message,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          console.log("error - modifyDocument");
        });
    };

    return (
      <>
        <Button
          variant="contained"
          color="warning"
          onClick={handleClickOpen}
          startIcon={<EditIcon />}
          autoFocus
        >
          Editar
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            component: "form",
            onSubmit: (event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const list_name = selectedRows[0].database;
              const doc_id = selectedRows[0].id;
              const name = formData.get("name");
              const email = formData.get("email");
              const phone = formData.get("phone");
              const company = formData.get("company");
              const role = formData.get("role");
              let requestBody = {
                list_name,
                doc_id,
                owner: userEmail,
                name,
                email,
                phone,
                company,
                role,
              };
              handleClose();
              modifyDocument(requestBody);
            },
          }}
        >
          <DialogTitle> Edición de campos </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Puedes modificar el valor de los siguientes campos
            </DialogContentText>
            <StyledDivSpacer $height={"2rem"} />
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <DynamicInputsComponent
                  formData={selectedRows.map((data) => {
                    return allowedFields.map((field) => {
                      return { name: field, value: data[field] };
                    });
                  })}
                />
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button type="submit" variant="contained">
              Aplicar cambios
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const DeleteDialog = () => {
    const [open, setOpen] = useState(false);
    const [documentId, setDocumentId] = useState("");
    const [tableName, setTableName] = useState("");

    const handleClickOpen = () => {
      const docId = selectedRows[0].id;
      const tableName = selectedRows[0].database;
      setDocumentId(docId);
      setTableName(tableName);
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
      setToggleCleared(!toggleCleared);
    };

    const deleteDocument = async (requestBody) => {
      await fetch(`${import.meta.env.VITE_API_URL}/lists/delete_document`, {
        method: "DELETE",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.hasOwnProperty("success")) {
            setDocEdited(!docEdited);
            Swal.fire({
              title: "Eliminado",
              text: data.content.message,
              icon: "success",
            });
          }
          if (data.hasOwnProperty("detail")) {
            Swal.fire({
              title: "Error",
              text: data.detail,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            title: "Error",
            text: err.message,
            icon: "error",
          });
        });
    };

    const handleAccept = () => {
      handleClose();
      const requestBody = {
        collections_and_ids: selectedRows.map((data) => ({
          database: data.database,
          document_id: data.id,
        })),
      };
      deleteDocument(requestBody);
    };

    return (
      <>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          startIcon={<DeleteIcon />}
          color="error"
        >
          ELIMINAR
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            ¿Seguro de eliminar los registros seleccionados?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Se eliminaran todos los datos asociados a estos registros
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              NO
            </Button>
            <Button
              variant="contained"
              onClick={handleAccept}
              startIcon={<DeleteIcon />}
              color="error"
              autoFocus
            >
              SI
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const MoveListDialog = () => {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
      setOpen(false);
      setToggleCleared(!toggleCleared);
      setSelectAllRegisters(false);
      setSelectedRows([]);
    };

    const handleClickOpen = () => {
      setOpen(true);
    };

    const moveMassiveDocuments = async (requestBody) => {
      await fetch(`${import.meta.env.VITE_API_URL}/lists/move_masive_docs`, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.hasOwnProperty("success")) {
            emptyTable();
            Swal.fire({
              title: "Finalizado",
              text: data.content,
              icon: "success",
            });
          }
          if (data.hasOwnProperty("detail")) {
            Swal.fire({
              title: "Error",
              text: data.detail,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            title: "Error",
            text: err.message,
            icon: "error",
          });
        });
    };

    const moveDocuments = async (requestBody) => {
      await fetch(`${import.meta.env.VITE_API_URL}/lists/move_documents`, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.hasOwnProperty("success")) {
            emptyTable();
            Swal.fire({
              title: "Finalizado",
              text: data.content,
              icon: "success",
            });
          }
          if (data.hasOwnProperty("detail")) {
            Swal.fire({
              title: "Error",
              text: data.detail,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            title: "Error",
            text: err.message,
            icon: "error",
          });
        });
    };

    const optionsByUserType = () => {
      if (isAdmin) return collectionsInfo;
      if (isSpammer) {
        let filteredCollectionsInfo = collectionsInfo.filter(
          (item) =>
            item.value !== "black_list" &&
            item.value !== "bad_list" &&
            item.value !== "unsubscribed"
        );
        return filteredCollectionsInfo;
      }
    };

    return (
      <>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          startIcon={<DriveFileMoveIcon />}
          color="primary"
        >
          MOVER
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            component: "form",
            onSubmit: (event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              let listNameToMove = formData.get("react-select-moving-docs");

              const selectedRowsList = selectedRows.reduce(
                (accumulator, { database, id }) => {
                  if (!accumulator[database]) {
                    accumulator[database] = [];
                  }

                  accumulator[database].push(id);
                  return accumulator;
                },
                {}
              );

              handleClose();

              let requestBody;

              const allRegistersCheckedValue = allRegistersChecked.current; // Usar el valor de la referencia

              if (allRegistersCheckedValue) {
                requestBody = {
                  list_name_to_move: listNameToMove,
                  search_criteria: search,
                  black_list: blackChecked,
                  unsubscribed_list: unsubscribedChecked,
                  bad_list: badChecked,
                  search_by_table: !allTablesChecked,
                  table_to_search: tableSelected,
                };
                moveMassiveDocuments(requestBody);
              } else {
                requestBody = {
                  list_name_to_move: listNameToMove,
                  list_names_with_doc_id: selectedRowsList,
                };
                moveDocuments(requestBody);
              }

              Swal.fire({
                title: "Movimiento en curso",
                text: "Se está moviendo la información...",
                allowOutsideClick: false,
                didOpen: () => {
                  Swal.showLoading();
                },
              });
            },
          }}
        >
          <DialogTitle id="alert-dialog-title">
            Seleccion la lista destino
          </DialogTitle>
          <DialogContent>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              name="react-select-moving-docs"
              placeholder="Selecciona una lista"
              required={true}
              options={optionsByUserType()}
              styles={customStylesSelector}
            />
            <StyledDivSpacer $height="10rem" />
            <DialogContentText id="alert-dialog-description">
              Los registros seleccionados se moveran a la lista seleccionada
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              CANCELAR
            </Button>
            <Button
              variant="contained"
              type="submit"
              startIcon={<DriveFileMoveIcon />}
              color="primary"
              autoFocus
            >
              CONFIRMAR
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
  };

  const customStyles = {
    rows: {
      style: {
        fontSize: "16px",
      },
    },
    headCells: {
      style: {
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
  };

  const BoxBasic = () => {
    return (
      <Box component="section" sx={{ p: 2, border: "1px dashed grey" }}>
        No hay registros para mostrar
      </Box>
    );
  };

  // createTheme('solarized', {
  //     text: {
  //         primary: '#268bd2',
  //         secondary: '#2aa198',
  //     },
  //     background: {
  //         default: '#002b36',
  //     },
  //     context: {
  //         background: '#cb4b16',
  //         text: '#FFFFFF',
  //     },
  //     divider: {
  //         default: '#073642',
  //     },
  //     action: {
  //         button: 'rgba(0,0,0,.54)',
  //         hover: 'rgba(0,0,0,.08)',
  //         disabled: 'rgba(0,0,0,.12)',
  //     },
  // }, 'dark');

  const contextActions = useMemo(() => {
    return (
      <Stack spacing={2} direction="row">
        {(isAdmin || isSpammer) && <MoveListDialog />}
        {isAdmin && selectedRows.length < 2 && <ModifyDialog />}
        {isAdmin && <DeleteDialog />}
      </Stack>
    );
  }, [selectedRows, toggleCleared]);

  let UserDataTableSettings = {
    title: "Contenido de las listas",
    contextMessage: {
      singular: "registro",
      plural: "registros",
      message: "seleccionados",
    },
    customStyles: customStyles,
    noDataComponent: <BoxBasic />,
  };

  const RowsPerPageOptions = [10, 50, 100, 200, 500];
  const sellerSettings = {
    columns: columns,
    data: data,
    pagination: true,
    paginationComponentOptions: paginationComponentOptions,
    striped: true,
    paginationRowsPerPageOptions: RowsPerPageOptions,
    highlightOnHover: true,
  };
  const selectableRowsSettings = {
    selectableRowsSingle: false,
    selectableRowsHighlight: true,
    contextActions: contextActions,
    onSelectedRowsChange: handleRowSelected,
    clearSelectedRows: toggleCleared,
  };
  const spammerSettings = {
    selectableRows: !allTablesChecked ? listOfOwner : true,
  };
  const adminSettings = {
    selectableRows: true,
  };

  switch (roleId) {
    case ROLES["Vendedor"]:
      UserDataTableSettings = { ...UserDataTableSettings, ...sellerSettings };
      break;
    case ROLES["Spammer"]:
      UserDataTableSettings = {
        ...UserDataTableSettings,
        ...sellerSettings,
        ...selectableRowsSettings,
        ...spammerSettings,
      };
      break;
    case ROLES["Administrador"]:
      UserDataTableSettings = {
        ...UserDataTableSettings,
        ...sellerSettings,
        ...selectableRowsSettings,
        ...adminSettings,
      };
      break;
  }

  const emptyTable = () => {
    setSelectedOptions([]);
    setData([]);
    setRegistersMatched(0);
  };

  const handleReactSelectChange = (selected) => {
    if (selected === null) {
      emptyTable();
    } else {
      setListOfOwner(selected.ownerEmail === userEmail);
      setTableSelected(selected.value);
      setDocEdited(!docEdited);
      setSelectedOptions(selected);
    }
  };

  const handleAllRegisters = (event) => {
    setSelectAllRegisters(event.target.checked);
  };

  const ReactSelect = ({ handleChange, value }) => {
    let totalOptions = [...collectionsInfo];
    const blackListKey = "black_list";
    const badListKey = "bad_list";
    const unsubscribedKey = "unsubscribed";

    const existsBlackList = totalOptions.some(
      (object) => object["value"] === blackListKey
    );
    const existsBadList = totalOptions.some(
      (object) => object["value"] === badListKey
    );
    const existsUnsubscribed = totalOptions.some(
      (object) => object["value"] === unsubscribedKey
    );

    if (!existsBlackList) {
      totalOptions.push({ value: "black_list", label: "Lista negra" });
    }

    if (!existsBadList) {
      totalOptions.push({ value: "bad_list", label: "Lista malos" });
    }

    if (!existsUnsubscribed) {
      totalOptions.push({ value: "unsubscribed", label: "Bajas" });
    }
    return (
      <Select
        name="react-select"
        isClearable={true}
        isSearchable={true}
        placeholder="Selecciona una lista"
        onChange={handleChange}
        options={totalOptions}
        value={value}
        styles={customStylesSelector}
      />
    );
  };

  const registersMessage = "¿Seleccionar todos los registros coincidentes?";

  const DataTableLoader = () => (
    <div style={{ padding: "24px" }}>
      <Spinner />
      <div>Solicitando datos...</div>
    </div>
  );

  const selectAllTablesMessage =
    isAdmin || isSeller ? "Buscar en todas las tablas" : "Buscar en mis tablas";

  return (
    <>
      <StyledDivSpacer />
      <FormControlLabel
        control={
          <Switch checked={allTablesChecked} onChange={handleAllTAblesCheck} />
        }
        label={selectAllTablesMessage}
      />
      <Stack spacing={2} direction="row">
        <Input
          type="text"
          placeholder="Buscar..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {allTablesChecked && (
          <>
            <FormControlLabel
              control={
                <Checkbox checked={blackChecked} onChange={handleBlackCheck} />
              }
              label="Lista negra"
            />
            <FormControlLabel
              control={
                <Checkbox checked={badChecked} onChange={handleBadCheck} />
              }
              label="Lista malos"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={unsubscribedChecked}
                  onChange={handleUnsubscribedCheck}
                />
              }
              label="Bajas"
            />
          </>
        )}
        {!allTablesChecked && (
          <ReactSelectorContainer>
            <ReactSelect
              handleChange={handleReactSelectChange}
              value={selectedOptions}
            />
          </ReactSelectorContainer>
        )}
        <Box component="section" sx={{ p: 1, border: "1px dashed grey" }}>
          <Typography>
            {" "}
            Registros encontrados: {registersMatched.toLocaleString()}{" "}
          </Typography>
        </Box>
      </Stack>
      <StyledDivSpacer />
      <DataTable
        {...UserDataTableSettings}
        progressPending={loadingTable}
        progressComponent={<DataTableLoader />}
      />
      {selectedRows.length >= 1000 && (
        <FormControlLabel
          control={
            <Checkbox
              checked={selectAllRegisters}
              onChange={handleAllRegisters}
            />
          }
          label={
            selectAllRegisters
              ? `${registersMessage} (${(
                  registersMatched - 1000
                ).toLocaleString()} registros adicionales seleccionados)`
              : registersMessage
          }
        />
      )}
    </>
  );
};

function ModuleThree() {
  return (
    <>
      <CssBaseline />
      <Container maxWidth="false">
        <SearchTable />
      </Container>
    </>
  );
}

export { ModuleThree };
