import Swal from "sweetalert2";

const decodeJWT = (accessToken) => {
  const parts = accessToken.split(".");
  if (parts.length !== 3) {
    throw new Error("Invalid Token");
  }

  const payload = parts[1];
  const decodedPayload = atob(payload);

  try {
    return JSON.parse(decodedPayload);
  } catch (e) {
    throw new Error("Error parsing JWT payload");
  }
};

export { decodeJWT };
