import { useState, useEffect, useContext } from "react";
import { AppContext } from "../../Pages/AuthContext";
import { decodeJWT } from "../../Components/Utilities";
import * as styledComponents from "styled-components";
import clean from "../../assets/best_clean_data.webp";
import garbage from "../../assets/delete_data.webp";
import differenceBy from "lodash/differenceBy";
import Select from "react-select";
import Swal from "sweetalert2";

import { Button, CardActions } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import LinearProgress from "@mui/material/LinearProgress";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: 50,
  lineHeight: "60px",
  padding: "0 12px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",

  "&:hover": {
    cursor: "pointer",
  },
}));

const StyledDivSpacer = styledComponents.styled.div`
    height: ${(props) => (props.$height ? props.$height : "1rem")};
`;

const StyledParagraph = styledComponents.styled.p`
    overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis;

    &:hover {
        cursor: 'pointer',
    }
`;

const darkTheme = createTheme({ palette: { mode: "dark" } });
const lightTheme = createTheme({ palette: { mode: "light" } });

const selectHeight = "33px";
const customStyles = {
  control: (base, state) => ({
    ...base,
    height: selectHeight,
    minHeight: selectHeight,
  }),
  valueContainer: (base, state) => ({
    ...base,
    height: selectHeight,
    padding: "0 6px",
  }),
  input: (base, state) => ({
    ...base,
    margin: "0px",
  }),
  indicatorsContainer: (base, state) => ({
    ...base,
    height: selectHeight,
  }),
};

function ModuleFour() {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsClean, setSelectedOptionsClean] = useState([]);
  const [cleanButton, setCleanButton] = useState(true);
  const [collectionsInfo, setCollectionsInfo] = useState([]);
  const [loading, setLoading] = useState(false);

  const { userData } = useContext(AppContext);

  const ROLES = {
    Vendedor: 1085,
    Spammer: 1197,
    Administrador: 1356,
  };

  let payload;
  let isAuthenticated;
  let userEmail;
  let isAdmin;
  let isSpammer;
  let isSeller;
  let roleId;

  if (userData.hasOwnProperty("access_token")) {
    payload = decodeJWT(userData.access_token);
    roleId = payload.role;
    isAuthenticated = roleId === 1085 || roleId === 1197 || roleId === 1356;
    userEmail = payload.email;
    isAdmin = roleId === ROLES["Administrador"];
    isSpammer = roleId === ROLES["Spammer"];
    isSeller = roleId === ROLES["Vendedor"];
  }

  const labelForBlackAndBadList = (value) => {
    if (value === "black_list") {
      return "Lista negra";
    }
    if (value === "bad_list") {
      return "Lista malos";
    }
    if (value === "unsubscribed") {
      return "Bajas";
    }
    return value;
  };

  const getAdminInfo = async () => {
    await fetch(`${import.meta.env.VITE_API_URL}/lists/for_admin`, {
      method: "POST",
      body: JSON.stringify({
        owner: userEmail,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const listOptions = data.content.map((item) => ({
          value: item.table_name,
          label: labelForBlackAndBadList(item.table_name),
          quantity: item.registers,
        }));
        setCollectionsInfo(listOptions);
      })
      .catch((err) => {
        console.log("error - getAdminInfo");
      });
  };

  const getSpammerInfo = async () => {
    fetch(`${import.meta.env.VITE_API_URL}/lists/owner_user`, {
      method: "POST",
      body: JSON.stringify({
        owner: userEmail,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const listOptions = data.content.map((item) => ({
          value: item.table_name,
          label: labelForBlackAndBadList(item.table_name),
          quantity: item.registers,
        }));
        setCollectionsInfo(listOptions);
      })
      .catch((err) => {
        console.log("error - getSpammerInfo");
      });
  };

  useEffect(() => {
    if (isAdmin) {
      getAdminInfo();
    }
    if (isSpammer) {
      getSpammerInfo();
    }
  }, [roleId]);

  const ReactSelect = ({ isMultiple, handleChange, options, value }) => {
    return (
      <>
        <Select
          isMulti={isMultiple}
          isClearable={true}
          isSearchable={true}
          menuPlacement="top"
          placeholder="Selecciona una lista"
          styles={customStyles}
          onChange={handleChange}
          options={options}
          value={value}
        />
      </>
    );
  };

  const CardComponent = ({ image, title, content, selectorComponent }) => {
    return (
      <Card sx={{ maxWidth: 450 }}>
        <div className="crazy-valter">
          <CardMedia component="img" height="140" image={image} />
          <CardContent>
            <div className="crazy-valter">
              <Typography gutterBottom variant="h5" component="div">
                {title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {content}
              </Typography>
            </div>
          </CardContent>
          <CardActions className="dexter-bad">
            <Grid item xs={8}>
              {selectorComponent}
            </Grid>
            <Grid item xs={4}></Grid>
          </CardActions>
        </div>
      </Card>
    );
  };

  const MultiActionAreaCard = () => {
    const handleRemoveSelectChange = (selected) => {
      setSelectedOptions(selected);
      setCollectionsInfo(differenceBy(collectionsInfo, selected, "value"));
    };

    const handleCleanSelectChange = (selected) => {
      setSelectedOptionsClean(selected);
      if (selected) {
        setCleanButton(false);
      } else {
        setCleanButton(true);
      }
    };

    const optionsByUserType = () => {
      if (isAdmin) return collectionsInfo;
      if (isSpammer) {
        let filteredCollectionsInfo = collectionsInfo.filter(
          (item) =>
            item.value !== "black_list" &&
            item.value !== "bad_list" &&
            item.value !== "unsubscribed"
        );
        return filteredCollectionsInfo;
      }
    };

    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <ThemeProvider theme={lightTheme}>
                  <Box
                    sx={{
                      p: 2,
                      borderRadius: 2,
                      bgcolor: "background.default",
                      display: "grid",
                      gridTemplateColumns: { md: "1fr" },
                      gap: 2,
                    }}
                  >
                    <Typography variant="h6" component="div">
                      Listas a remover
                    </Typography>
                    {selectedOptions.map((item, index) => (
                      <Tooltip title={item.value} placement="top">
                        <Item key={index} elevation={3}>
                          {`(${item.quantity}) ${item.value}`}
                        </Item>
                      </Tooltip>
                    ))}
                  </Box>
                </ThemeProvider>
              </Grid>
              <Grid item xs={7}>
                <CardComponent
                  selectorComponent={
                    <ReactSelect
                      isMultiple={true}
                      handleChange={handleRemoveSelectChange}
                      options={collectionsInfo}
                      value={selectedOptions}
                    />
                  }
                  image={garbage}
                  title={"Remover"}
                  content={
                    "Selecciona las listas que contengan la información que deseas remover"
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <CardComponent
                  selectorComponent={
                    <ReactSelect
                      isMultiple={true}
                      handleChange={handleCleanSelectChange}
                      options={optionsByUserType()}
                      value={selectedOptionsClean}
                    />
                  }
                  image={clean}
                  title={"Lista a ser depurada"}
                  content={
                    "Selecciona la lista a la cual deseas limpiar quitándole la información que selecciones a la izquierda"
                  }
                />
                <ButtonContainer />
              </Grid>
              <Grid item xs={5}>
                <ThemeProvider theme={lightTheme}>
                  <Box
                    sx={{
                      p: 2,
                      borderRadius: 2,
                      bgcolor: "background.default",
                      display: "grid",
                      gridTemplateColumns: { md: "1fr" },
                      gap: 2,
                    }}
                  >
                    <Typography variant="h6" component="div">
                      Listas a limpiar
                    </Typography>
                    {selectedOptionsClean.map((item, index) => (
                      <Tooltip title={item.value} placement="top">
                        <Item key={index} elevation={3}>
                          {`(${item.quantity}) ${item.value}`}
                        </Item>
                      </Tooltip>
                    ))}
                  </Box>
                </ThemeProvider>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const removeGarbageEmails = async (requestBody) => {
    await fetch(`${import.meta.env.VITE_API_URL}/lists/clean`, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setCleanButton(false);
        if (
          data.hasOwnProperty("success") &&
          data.content.hasOwnProperty("deleted")
        ) {
          if (data.content.deleted) {
            let textReport = "";
            const dictionary = data.content.report;

            for (const listName in dictionary) {
              textReport += `
                            <p> 
                                A la lista <b>${listName}</b> se le han eliminado <b>${dictionary[listName]}</b> emails
                            </p>
                            <br/>`;
            }

            const title = "== Breve reporte de la depuración ==";
            const message =
              "<p>Se ha descargado un archivo csv con el listado de emails eliminados.</p>";
            const separator = "---------------------------------";
            Swal.fire({
              title: "Depuración completada",
              html: `<p>De manera global ${data.content.message}</p><br/><h1>${title}</h1><br/>${textReport}<br/>${separator}<br/>${message}`,
              icon: "success",
            });
            // Descargamos csv con listado de emails removidos
            const fileUrl = data.content.file_name;
            window.location.href = fileUrl;
          }
          if (!data.content.deleted) {
            Swal.fire({
              title: "La Depuración no es posible",
              html: `<p>${data.content.message}</p><br/><p>En la lista seleccionada para limpieza no se encontró ninguno de los emails seleccionados para depuración</p>`,
              icon: "warning",
            });
          }
        }
        if (data.hasOwnProperty("detail")) {
          Swal.fire({
            title: "Ha habido un problema",
            text: data.detail,
            icon: "warning",
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        setCleanButton(false);
        console.log("error - removeGarbageEmails");
      });
  };

  const ButtonContainer = () => {
    const sendData = () => {
      const requestBody = {
        list_names_remove: selectedOptions.map((item) => item.value),
        list_names_clean: selectedOptionsClean.map((item) => item.value),
      };
      setLoading(true);
      setCleanButton(true);
      removeGarbageEmails(requestBody);
    };

    return (
      <Container maxWidth="sm">
        {loading ? (
          <>
            <StyledDivSpacer $height="2rem" />
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h6" component="div">
                Depurando...
              </Typography>
              <LinearProgress />
            </Box>
          </>
        ) : null}
        <StyledDivSpacer $height="2rem" />
        <Grid container spacing={2}>
          <Grid item xs={4}></Grid>
          <Grid item xs={4} style={{ margin: "0 24px" }}>
            <Box sx={{ flexGrow: 1 }}>
              <Button
                size="large"
                variant="contained"
                disabled={cleanButton}
                onClick={sendData}
              >
                Depurar
              </Button>
            </Box>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      </Container>
    );
  };

  const SimpleContainer = () => {
    return (
      <>
        <CssBaseline />
        <StyledDivSpacer $height="8rem" />
        <Container maxWidth="xl">
          <MultiActionAreaCard />
          <StyledDivSpacer $height="3rem" />
        </Container>
      </>
    );
  };

  return (
    <>
      <SimpleContainer />
    </>
  );
}

export { ModuleFour };
