import { Table } from "./DataTable";
import CssBaseline from "@mui/material/CssBaseline";
import Swal from "sweetalert2";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useState, useEffect, useContext } from "react";
import { AppContext } from "../../Pages/AuthContext";
import { decodeJWT } from "../../Components/Utilities";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import styled from "styled-components";
import { Stack } from "@mui/material";

const StyledDivSpacer = styled.div`
  height: 1rem;
`;

const StyledDataTable = styled(Table)`
  margin-top: 1px;
`;

const ModuleOne = () => {
  const { updateTheRows, listData, updateListData, userData } =
    useContext(AppContext);

  const ROLES = {
    Vendedor: 1085,
    Spammer: 1197,
    Administrador: 1356,
  };

  let payload;
  let isAuthenticated;
  let userEmail;
  let isAdmin;
  let isSpammer;
  let isSeller;
  let roleId;

  if (userData.hasOwnProperty("access_token")) {
    payload = decodeJWT(userData.access_token);
    roleId = payload.role;
    isAuthenticated = roleId === 1085 || roleId === 1197 || roleId === 1356;
    userEmail = payload.email;
    isAdmin = roleId === ROLES["Administrador"];
    isSpammer = roleId === ROLES["Spammer"];
    isSeller = roleId === ROLES["Vendedor"];
  }

  const AddNewListDialog = () => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const createNewList = async (table_name) => {
      await fetch(`${import.meta.env.VITE_API_URL}/lists/`, {
        method: "POST",
        body: JSON.stringify({
          list_name: table_name,
          owner: userEmail,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.hasOwnProperty("success")) {
            updateListData([...listData, data.content]);
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Lista creada!",
              showConfirmButton: false,
              timer: 1500,
            });
          }
          if (data.hasOwnProperty("detail")) {
            Swal.fire({
              title: "Ha ocurrido un error",
              text: data.detail,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            title: "Error!",
            text: err.message,
            icon: "error",
          });
        });
    };

    return (
      <>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            variant="contained"
            onClick={handleClickOpen}
            startIcon={<AddCircleOutlineIcon />}
            color="primary"
            autoFocus
          >
            Agregar lista
          </Button>
        </Stack>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            component: "form",
            onSubmit: (event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              let newListName = formData.get("new-list");
              createNewList(newListName);
              handleClose();
            },
          }}
        >
          <DialogTitle>Crear nueva lista</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ingresa el nombre de la lista que deseas crear. No puedes utilizar
              el mismo nombre de una lista existente.
            </DialogContentText>
            <TextField
              autoFocus
              required
              margin="dense"
              id="new-list"
              name="new-list"
              label="Nueva lista"
              type="text"
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button type="submit">Crear</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const getAdminInfo = async () => {
    fetch(`${import.meta.env.VITE_API_URL}/lists/for_admin`, {
      method: "POST",
      body: JSON.stringify({
        owner: userEmail,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let dataInfo = data.content;
        dataInfo.forEach((item) => {
          if (item.table_name === "black_list") {
            item.table_name = "Lista negra";
          }
          if (item.table_name === "bad_list") {
            item.table_name = "Lista mala";
          }
          if (item.table_name === "unsubscribed") {
            item.table_name = "Bajas";
          }
        });
        updateListData(dataInfo);
      })
      .catch((err) => {
        console.log("error - getAdminInfo");
      });
  };

  const getSpammerInfo = async () => {
    fetch(`${import.meta.env.VITE_API_URL}/lists/owner_user`, {
      method: "POST",
      body: JSON.stringify({
        owner: userEmail,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let listsInfo = data.content;
        let filteredCollectionsInfo = listsInfo.filter(
          (item) =>
            item.table_name !== "black_list" &&
            item.table_name !== "bad_list" &&
            item.table_name !== "unsubscribed"
        );
        updateListData(filteredCollectionsInfo);
      })
      .catch((err) => {
        console.log("error - getSpammerInfo");
      });
  };

  useEffect(() => {
    if (isAdmin) {
      getAdminInfo();
    }
    if (isSpammer) {
      getSpammerInfo();
    }
  }, [updateTheRows, roleId]);

  return (
    <>
      <CssBaseline />
      <Container maxWidth="false" margin="15px">
        <StyledDivSpacer />
        <AddNewListDialog />
        <StyledDivSpacer />
        <StyledDataTable data={listData} />
      </Container>
    </>
  );
};

export { ModuleOne };
