import { useState, useEffect, useContext, forwardRef, Fragment } from "react";
import { AppContext } from "../../Pages/AuthContext";
import { decodeJWT } from "../../Components/Utilities";
import differenceBy from "lodash/differenceBy";
import image from "../../assets/reptile.jpg";

import Select from "react-select";
import Swal from "sweetalert2";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const StyledDivHeight = styled.div`
  height: ${(props) => props.$height || "1rem"};
`;

const StyledSpan = styled.span`
  font-weight: bold;
  color: #ff0000;
`;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const initialFieldNames = [
  // id y database no deben estar, porque el id se genera al insertarse y la base de datos la selecciona el usuario
  // { value: 'id', label: 'ID' },
  // { value: 'database', label: 'Base de datos' },
  { id: 1, value: "name", label: "Nombre" },
  { id: 2, value: "email", label: "Email" },
  { id: 3, value: "phone", label: "Teléfono" },
  { id: 4, value: "company", label: "Empresa" },
  { id: 5, value: "role", label: "Puesto" },
];

function ModuleTwo() {
  const [collectionsInfo, setCollectionsInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [systemPhase, setSystemPhase] = useState(0);
  const [hack, setHack] = useState(false);
  const [listConfirmed, setListConfirmed] = useState(false);
  const [listToInsert, setListToInsert] = useState("");

  const { userData } = useContext(AppContext);

  const ROLES = {
    Vendedor: 1085,
    Spammer: 1197,
    Administrador: 1356,
  };

  let payload;
  let isAuthenticated;
  let userEmail;
  let isAdmin;
  let isSpammer;
  let isSeller;
  let roleId;

  const optionsByUserType = () => {
    if (isAdmin) return collectionsInfo;
    if (isSpammer) {
      let filteredCollectionsInfo = collectionsInfo.filter(
        (item) =>
          item.value !== "black_list" &&
          item.value !== "bad_list" &&
          item.value !== "unsubscribed"
      );
      return filteredCollectionsInfo;
    }
  };

  if (userData.hasOwnProperty("access_token")) {
    payload = decodeJWT(userData.access_token);
    roleId = payload.role;
    isAuthenticated = roleId === 1085 || roleId === 1197 || roleId === 1356;
    userEmail = payload.email;
    isAdmin = roleId === ROLES["Administrador"];
    isSpammer = roleId === ROLES["Spammer"];
    isSeller = roleId === ROLES["Vendedor"];
  }

  const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const customStyles = {
    control: (base, state) => ({
      ...base,
      height: "56px",
      minHeight: "56px",
    }),
    valueContainer: (base, state) => ({
      ...base,
      height: "56px",
      padding: "0 6px",
    }),
    input: (base, state) => ({
      ...base,
      margin: "0px",
    }),
    indicatorsContainer: (base, state) => ({
      ...base,
      height: "56px",
    }),
  };

  const ReactSelect = ({ elements, naming = "react-select", isRequired }) => {
    return (
      <>
        <Select
          className="basic-single"
          classNamePrefix="select"
          isLoading={isLoading}
          isClearable={true}
          isSearchable={true}
          name={naming}
          placeholder="Selecciona una lista"
          required={isRequired}
          options={elements}
          styles={customStyles}
        />
      </>
    );
  };

  const LastPhaseDialog = () => {
    const [open, setOpen] = useState(false);
    const [fieldNames, setFieldNames] = useState(initialFieldNames);
    const [selectedOptions, setSelectedOptions] = useState({});

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleSelectChange = (selected, naming) => {
      const currentSelection = [selected];
      setFieldNames(differenceBy(fieldNames, currentSelection, "value"));
      setSelectedOptions({ ...selectedOptions, [naming]: selected });
    };

    const CustomReactSelect = ({ naming, handleChange, value, index }) => {
      return (
        <>
          <Select
            key={index}
            isMulti={false}
            isClearable={true}
            isSearchable={true}
            name={naming}
            required={false}
            placeholder="Selecciona una lista"
            styles={customStyles}
            onChange={handleChange}
            options={fieldNames}
            value={value}
          />
        </>
      );
    };

    const writeFileDataInList = async (data) => {
      await fetch(`${import.meta.env.VITE_API_URL}/lists/write_file`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (
            data.hasOwnProperty("success") &&
            data.content.hasOwnProperty("repeated")
          ) {
            if (data.content.repeated && data.content.emails_inserted === 0) {
              Swal.fire({
                title: "Ningún email fué insertado",
                text: "Todos los emails se econtraban en la lista seleccionada",
                icon: "warning",
              });
            }
            if (data.content.repeated && data.content.emails_inserted > 0) {
              Swal.fire({
                title: "Inserción con advertencia",
                html: `<p>${data.content.emails_inserted.toLocaleString()} emails insertados.</p> <br/> <p>${
                  data.content.message
                }</p>`,
                icon: "warning",
              });

              // Descargamos csv con listado de emails no insertados
              const fileUrl = data.content.file_name;
              window.location.href = fileUrl;
            }

            if (!data.content.repeated && data.content.emails_inserted > 0) {
              Swal.fire({
                title: "Inserción exitosa",
                text: `${data.content.emails_inserted.toLocaleString()} emails fueron insertados`,
                icon: "success",
              });
            }
          }
          if (data.hasOwnProperty("detail")) {
            Swal.fire({
              title: "Ha habido un problema",
              text: data.detail,
              icon: "warning",
            });
          }
          setListConfirmed(false);
        })
        .catch((err) => {
          console.log("error - writeFileDataInList");
        });
    };

    return (
      <>
        <div className="button-fields" style={{ width: "100%" }}>
          <Button
            disableElevation
            variant="contained"
            color="warning"
            onClick={handleClickOpen}
            style={{
              width: "170px",
              margin: "0 34%",
            }}
          >
            Asignar Campos
          </Button>
        </div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          fullWidth={true}
          maxWidth={"sm"}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{
            component: "form",
            onSubmit: (event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              let list_name = listToInsert;
              let dataToSend = {};
              if (list_name === "Lista negra") list_name = "black_list";
              if (list_name === "Lista malos") list_name = "bad_list";
              if (list_name === "Bajas") list_name = "unsubscribed";
              dataToSend["list_name"] = list_name;
              dataToSend["file_name"] = fileName;
              dataToSend["owner"] = userEmail;
              let header;
              csvData.forEach((item, index) => {
                header = formData.get(`react-select-${index}`);
                if (header !== "" && header !== null && header !== undefined) {
                  dataToSend[header] = index;
                }
              });

              if (!dataToSend.hasOwnProperty("email")) {
                handleClose();
                Swal.fire({
                  title: "Ha habido un problema",
                  text: "Debes seleccionar al menos un campo para el email",
                  icon: "warning",
                });
                setSystemPhase(0);
                return;
              }

              writeFileDataInList(dataToSend);

              Swal.fire({
                title: "Insertando",
                text: "Guardando la información...",
                allowOutsideClick: false,
                didOpen: () => {
                  Swal.showLoading();
                },
              });

              event.target.reset();
              handleClose();
              setSystemPhase(0); // regresamos a la primera pantalla de éste módulo
            },
          }}
        >
          <DialogTitle>Asignación de campos</DialogTitle>
          <DialogContent sx={{ minHeight: 450 }}>
            <DialogContentText>
              Selecciona los tipos de campo correspondientes
              <br />
              Archivo: {fileName}
            </DialogContentText>
            <StyledDivHeight $height="2em" />
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography
                    variant="button"
                    display="block"
                    gutterBottom
                    paddingTop={"18px"}
                    fontWeight={"bold"}
                  >
                    Campos de archivo
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="button"
                    display="block"
                    gutterBottom
                    paddingTop={"18px"}
                    fontWeight={"bold"}
                  >
                    Nombres de campo
                  </Typography>
                </Grid>
                {csvData.map((item, index) => (
                  <Fragment key={index}>
                    <Grid item xs={6}>
                      <CustomReactSelect
                        naming={`react-select-${index}`}
                        handleChange={(selected) =>
                          handleSelectChange(selected, `react-select-${index}`)
                        }
                        value={selectedOptions[`react-select-${index}`]}
                        key={index}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body2"
                        display="block"
                        gutterBottom
                        paddingTop={"18px"}
                      >
                        {item}
                      </Typography>
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cerrar</Button>
            <Button type="submit">Insertar</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const SelectDestinationList = () => {
    const handleSendDestination = (event) => {
      event.preventDefault();
      const formData = new FormData(event.currentTarget);
      let list_name = formData.get("react-select");
      if (list_name === "black_list") list_name = "Lista negra";
      if (list_name === "bad_list") list_name = "Lista malos";
      if (list_name === "unsubscribed") list_name = "Bajas";
      setListToInsert(list_name);
      setListConfirmed(true);
    };

    return (
      <Card sx={{ minWidth: 550, minHeight: 480 }}>
        <form onSubmit={handleSendDestination}>
          <CardMedia sx={{ height: 200 }} image={image} title="green iguana" />
          <CardContent>
            {listConfirmed ? (
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                style={{ textAlign: "center" }}
              >
                La lista confirmada ha sido:
                <br />
                <StyledSpan> "{listToInsert}" </StyledSpan>
              </Typography>
            ) : (
              <>
                <Typography gutterBottom variant="h5" component="div">
                  Selecciona lista para ingresar datos del archivo
                </Typography>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={7}>
                      <ReactSelect
                        elements={optionsByUserType()}
                        isRequired={true}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Typography
                        variant="button"
                        display="block"
                        gutterBottom
                        paddingTop={"18px"}
                        fontWeight={"bold"}
                      >
                        Lista Destino
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}
            <StyledDivHeight />
          </CardContent>
          <StyledDivHeight $height={"4em"} />
          <CardActions>
            {listConfirmed ? (
              <LastPhaseDialog />
            ) : (
              <Button type="submit" variant="contained">
                Confirmar lista
              </Button>
            )}
          </CardActions>
        </form>
      </Card>
    );
  };

  const MediaCard = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleFileChange = (event) => {
      const file = event.target.files[0];
      setSelectedFile(file);
    };

    const clearFile = () => {
      setSelectedFile(null);
      setFileName("");
    };

    const sendFileToBackend = async (formData) => {
      await fetch(`${import.meta.env.VITE_API_URL}/lists/save_file`, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          setFileName(data.content.filename);
          setCsvData(data.content.data);
          setSystemPhase(1);
        })
        .catch((err) => {
          console.log("error - sendFileToBackend");
        });
    };

    const uploadFile = () => {
      if (!selectedFile) {
        Swal.fire({
          title: "Ha ocurrido un error",
          text: "Por favor, selecciona un archivo primero.",
          icon: "warning",
        });
        return;
      }

      setLoading(true);
      const formData = new FormData();
      formData.append("file", selectedFile);

      sendFileToBackend(formData);
    };

    return (
      <Card
        sx={{ minWidth: 550, minHeight: 400 }}
        style={{ boxShadow: "10px 10px 21px 0 rgba(0,0,0,0.75)" }}
      >
        <CardMedia sx={{ height: 200 }} image={image} title="green iguana" />
        <div style={{ margin: "10px 25px 25px" }}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Carga de archivos CSV
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Selecciona un archivo compatible para subir al sistema
            </Typography>
            <StyledDivHeight />
            {selectedFile && <p>{selectedFile.name}</p>}
            <StyledDivHeight />
            <Button
              component="label"
              multiple
              variant="outlined"
              tabIndex={-1}
              startIcon={<AttachFileIcon />}
              onChange={handleFileChange}
            >
              Seleccionar archivo
              <VisuallyHiddenInput type="file" />
            </Button>
          </CardContent>
          <CardActions>
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              size="medium"
              startIcon={<CloudUploadIcon />}
              variant="contained"
              onClick={uploadFile}
            >
              Cargar datos
            </LoadingButton>
            <Button size="medium" onClick={clearFile}>
              Limpiar
            </Button>
          </CardActions>
        </div>
      </Card>
    );
  };

  const getAdminInfo = async () => {
    await fetch(`${import.meta.env.VITE_API_URL}/lists/`)
      .then((response) => response.json())
      .then((data) => {
        const listOptions = data.content.map((item) => ({
          value: item.table_name,
          label: item.table_name,
        }));
        listOptions.push(
          { value: "black_list", label: "Lista negra" },
          { value: "bad_list", label: "Lista malos" },
          { value: "unsubscribed", label: "Bajas" }
        );
        setCollectionsInfo(listOptions);
      })
      .catch((err) => {
        console.log("error - getAdminInfo");
      });
  };

  const getSpammerInfo = async () => {
    fetch(`${import.meta.env.VITE_API_URL}/lists/owner_user`, {
      method: "POST",
      body: JSON.stringify({
        owner: userEmail,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const listOptions = data.content.map((item) => ({
          value: item.table_name,
          label: item.table_name,
        }));
        setCollectionsInfo(listOptions);
      })
      .catch((err) => {
        console.log("error - getSpammerInfo");
      });
  };

  useEffect(() => {
    if (isAdmin) {
      getAdminInfo();
    }
    if (isSpammer) {
      getSpammerInfo();
    }
  }, [hack, roleId]);

  const InserManualDialog = () => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const translateToEnglish = (word) => {
      const translations = {
        Nombre: "name",
        Email: "email",
        Teléfono: "phone",
        Empresa: "company",
        Puesto: "role",
      };
      return translations[word];
    };

    const writeDataInList = async (data) => {
      await fetch(`${import.meta.env.VITE_API_URL}/lists/write_one`, {
        method: "POST",
        body: JSON.stringify({
          list_name: data.list_name,
          owner: userEmail,
          name: data.name,
          email: data.email,
          phone: data.phone,
          company: data.company,
          role: data.role,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.hasOwnProperty("success")) {
            Swal.fire({
              title: "Inserción exitosa",
              text: "La información se ha insertado correctamente",
              icon: "success",
            });
          }

          if (data.hasOwnProperty("detail")) {
            Swal.fire({
              title: "Ha habido un problema",
              text: data.detail,
              icon: "warning",
            });
          }
        })
        .catch((err) => {
          console.log("error - writeDataInList");
        });
    };

    return (
      <>
        <Button variant="contained" color="warning" onClick={handleClickOpen}>
          Insertar Manualmente
        </Button>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          fullWidth={true}
          maxWidth={"sm"}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{
            component: "form",
            onSubmit: (event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              let listName = formData.get("react-select");

              if (!listName) {
                handleClose();
                Swal.fire({
                  title: "Ha habido un problema",
                  text: "Selecciona una lista",
                  icon: "warning",
                });
                return;
              }

              let datoToSend = {
                list_name: listName,
                name: formData.get("name"),
                email: formData.get("email"),
                phone: formData.get("phone"),
                company: formData.get("company"),
                role: formData.get("role"),
              };

              writeDataInList(datoToSend);
              handleClose();
              setHack(!hack);
              event.target.reset();
            },
          }}
        >
          <DialogTitle>Inserción manual</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ingresa información de un registro en la lista deseada
            </DialogContentText>
            <StyledDivHeight $height="2em" />
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Typography
                    variant="button"
                    display="block"
                    gutterBottom
                    paddingTop={"18px"}
                    fontWeight={"bold"}
                  >
                    Lista Destino
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <ReactSelect
                    elements={optionsByUserType()}
                    isRequired={true}
                  />
                </Grid>
                {["Nombre", "Email", "Teléfono", "Empresa", "Puesto"].map(
                  (fieldName, index) => (
                    <Fragment key={index}>
                      <Grid item xs={5}>
                        <Typography
                          variant="button"
                          display="block"
                          gutterBottom
                          paddingTop={"18px"}
                          fontWeight={"bold"}
                        >
                          {fieldName}
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <TextField
                          autoFocus
                          margin="dense"
                          id={`${fieldName}`}
                          name={translateToEnglish(fieldName)}
                          label={`Valor para ${fieldName}`}
                          type="text"
                          fullWidth
                          variant="standard"
                          required={fieldName === "Email"}
                        />
                      </Grid>
                    </Fragment>
                  )
                )}
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cerrar</Button>
            <Button type="submit">Insertar</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  return (
    <>
      <StyledDivHeight $height="10rem" />
      {systemPhase === 0 ? (
        <>
          {" "}
          <MediaCard />
          <StyledDivHeight $height="4rem" />
          <div className="manual-dialog-container">
            <InserManualDialog />
          </div>
        </>
      ) : null}
      {systemPhase === 1 ? <SelectDestinationList /> : null}
    </>
  );
}

export { ModuleTwo };
