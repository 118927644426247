import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useContext, useState, useEffect } from "react";
import { AppContext } from "../../Pages/AuthContext";
import { decodeJWT } from "../Utilities";
import Swal from "sweetalert2";

const StyledList = styled.li`
  color: ${(props) => (props.$custom ? "rgb(255 255 255 / 0.6)" : "#FFFFFF")};
`;

const Navbar = () => {
  const navigate = useNavigate();
  const activeStyle = "underline underline-offset-4 font-bold text-cyan-300";
  const { userData, deleteDataUser } = useContext(AppContext);

  const ROLES = {
    Vendedor: 1085,
    Spammer: 1197,
    Administrador: 1356,
  };

  let payload;
  let isAuthenticated;
  let userEmail;
  let isAdmin;
  let isSpammer;
  let isSeller;

  const logout = () => {
    localStorage.setItem("loggedOut", Date.now());
    deleteDataUser();
    navigate("/");
  };

  if (userData.hasOwnProperty("access_token")) {
    payload = decodeJWT(userData.access_token);
    let roleId = payload.role;
    isAuthenticated = roleId === 1085 || roleId === 1197 || roleId === 1356;
    userEmail = payload.email;
    isAdmin = roleId === ROLES["Administrador"];
    isSpammer = roleId === ROLES["Spammer"];
    isSeller = roleId === ROLES["Vendedor"];
  }

  useEffect(() => {
    const handleLogout = (event) => {
      if (event.key === "loggedOut") {
        deleteDataUser();
        if (location.pathname !== "/") {
          navigate("/");
        }
      }
    };

    window.addEventListener("storage", handleLogout);

    return () => {
      window.removeEventListener("storage", handleLogout);
    };
  }, [navigate, location.pathname]);

  return (
    <nav
      className="flex justify-between items-center fixed z-10 top-0 w-full py-5 px-8 text-sm font-light"
      style={{
        background:
          "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(116,32,102,1) 39%, rgba(9,118,157,1) 100%)",
      }}
    >
      <ul className="flex items-center gap-3">
        <StyledList>
          {isAuthenticated && (isAdmin || isSpammer) ? (
            <NavLink
              to="/module-one"
              className={({ isActive }) =>
                isActive ? activeStyle : "undefined"
              }
            >
              Listas
            </NavLink>
          ) : null}
        </StyledList>
        <StyledList>
          {isAuthenticated && (isAdmin || isSpammer) ? (
            <NavLink
              to="/module-two"
              className={({ isActive }) =>
                isActive ? activeStyle : "undefined"
              }
            >
              Importación
            </NavLink>
          ) : null}
        </StyledList>
        <StyledList>
          {isAuthenticated && (isAdmin || isSpammer || isSeller) ? (
            <NavLink
              to="/module-three"
              className={({ isActive }) =>
                isActive ? activeStyle : "undefined"
              }
            >
              Búsqueda
            </NavLink>
          ) : null}
        </StyledList>
        <StyledList>
          {isAuthenticated && (isAdmin || isSpammer) ? (
            <NavLink
              to="/module-four"
              className={({ isActive }) =>
                isActive ? activeStyle : "undefined"
              }
            >
              Depuración
            </NavLink>
          ) : null}
        </StyledList>
      </ul>

      <ul className="flex items-center gap-3">
        <StyledList $custom={true}>
          {isAuthenticated ? userEmail : null}
        </StyledList>
        <StyledList>
          {isAdmin ? (
            <NavLink
              to="/admin-dashboard"
              className={({ isActive }) =>
                isActive ? activeStyle : "undefined"
              }
            >
              Panel administrativo
            </NavLink>
          ) : null}
        </StyledList>
        <StyledList>
          {isAuthenticated ? (
            <NavLink
              to="/my-account"
              className={({ isActive }) =>
                isActive ? activeStyle : "undefined"
              }
            >
              Mi cuenta
            </NavLink>
          ) : null}
        </StyledList>
        <StyledList>
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? activeStyle : "undefined")}
            onClick={isAuthenticated ? logout : ""}
          >
            {isAuthenticated ? "Cerrar sesión" : "Iniciar sesión"}
          </NavLink>
        </StyledList>
      </ul>
    </nav>
  );
};

export { Navbar };
